(function ($) {

    /**
     * TabChange plugin
     */
    $.fn.tabChange = function () {

        let el                      = $(this),
            $linksContainer         = el.find('.tab-change__links'),
            $contentContainer       = el.find('.tab-change__content').first(),
            contents                = {},
            fadeDuration            = 500,
            actualAnchor            = window.location.hash.replace("#", ""),
            activeContent;

        /**
         * Init tab change
         *
         * - Set up the active item in menu and content
         * - If there is already a ID in the URL, get the good tab
         */
        let init = () => {
            if (el.length > 0) {
                // Sticky menu
                $linksContainer.stick_in_parent({
                    offset_top: 50
                });
                $(window).on('load resize', function () {
                    $linksContainer.css('height', 'auto').height($linksContainer.outerHeight());
                });

                getContents();

                $linksContainer.find('a').first().addClass('is-active');
                activeContent = $linksContainer.find('a').first().attr('href').replace('#', '');
                setTimeout(function () {
                    $contentContainer.animate({
                        'opacity': 1
                    }, fadeDuration);
                }, fadeDuration);

                // if (!actualAnchor) {
                //     $linksContainer.find('a').first().addClass('is-active');
                //     activeContent = $linksContainer.find('a').first().attr('href').replace('#', '');
                // } else {
                //     $linksContainer.find(`a[href="#${actualAnchor}"]`).addClass('is-active');
                //
                //     $contentContainer.html(contents[actualAnchor]);
                //
                //     activeContent = actualAnchor;
                // }

                $linksContainer.find('a').on('click', function (e) {
                    e.preventDefault();

                    let ID = $(this).attr('href').replace('#', '');

                    if (ID !== activeContent) {
                        $contentContainer.fadeOut(fadeDuration, function () {
                            $(this).html(contents[ID]);

                            $(this).css({
                               'display': 'block',
                               'opacity': 0
                            });

                            $contentContainer.find('.twentytwenty-container').each(function () {
                                $(this).unwrap();
                                $(this).find('.twentytwenty-overlay').remove();
                                $(this).find('.twentytwenty-handle').remove();
                                $(this).twentytwenty();
                            });

                            setTimeout(function () {
                                $contentContainer.animate({
                                    'opacity': 1
                                }, fadeDuration);
                            }, 100);
                        });

                        activeContent = ID;

                        // Set up active link
                        $linksContainer.find('.is-active').removeClass('is-active');
                        $(this).addClass('is-active');
                    }
                });
            }
        };

        /**
         * Get all contents in object
         */
        let getContents = () => {
            $linksContainer.find('a').each(function () {
               let ID = $(this).attr('href').replace('#', '');

               contents[ID] = el.find(`.tab-change__content[id="${ID}"] > div`);
            });
        };

        init();
    };

})(jQuery);