(function ($) {
    $.fn.anchorBlock = function (options) {
        let settings = $.extend({
            linksContainer: '.anchor-block__links',
            contentContainer: '.anchor-block__content',
            useStickInParentPlugin: true
        }, options);

        let el            = $(this),
            anchors       = {},
            lastScrollTop = 0,
            activeLink, activeLinkID, activeContent;

        /**
         * Is the element is visible in the viewport ?
         *
         * @returns {boolean}
         */
        $.fn.visibleInViewPort = function () {
            let el              = $(this),
                topOfElement    = el.offset().top,
                bottomOfElement = topOfElement + el.outerHeight(),
                bottomOfScreen  = $(window).scrollTop() + $(window).innerHeight(),
                topOfScreen     = $(window).scrollTop();

            if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)) return true;
            return false;
        };

        /**
         * Init the anchor
         */
        let init = () => {
            if (el.length > 0) {
                // Sticky links
                if (settings.useStickInParentPlugin) {
                    el.find($(settings.linksContainer)).stick_in_parent({
                        offset_top: 50
                    });
                    $(window).on('load resize', function () {
                       el.find($(settings.linksContainer)).css('height', 'auto').height(el.find($(settings.linksContainer)).outerHeight());
                    });
                }

                // Get anchors in an array
                getAnchors();

                // On load comportment
                $(window).on('load', activateLink);

                // Scroll comportment
                $(window).on('scroll', getActualScrolledID);
            }
        };

        /**
         * Activate link
         *
         * 1 - First one if not in the anchor block
         * 2 - Good one if on the anchor block
         */
        let activateLink = () => {
            if (!el.visibleInViewPort()) {
                /* 1 - First one if not in the anchor block*/

                // Activate the first link
                let firstLink = el.find($(settings.linksContainer)).find('a').first();

                // Set up some global variables to find out the active link
                // & the active ID
                // & the active content element
                firstLink.addClass('is-active');
                activeLink = firstLink;
                activeLinkID = activeLink.attr('href').replace('#', '');
                activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);
            } else if (el.visibleInViewPort()) {
                /* 2 - Good one if on the anchor block */

                let scrollPos = $(window).scrollTop(),
                    positions = {};

                for (let id in anchors) {
                    let element             = el.find($(settings.contentContainer)).find(`[id="${id}"]`),
                        elementHeight       = element.outerHeight(),
                        elementOffsetTop    = element.offset().top,
                        position            = ((elementOffsetTop - 60)) - scrollPos;

                    positions[id] = position;
                }

                let closest = 0,
                    closestID;

                // Get the closest number to 0 (it is the good one to activate)
                for (let id in positions) {
                    if (closest === 0) {
                        closest = positions[id];
                        closestID = id;
                    } else if (positions[id] > 0 && positions[id] <= Math.abs(closest)) {
                        closest = positions[id];
                        closestID = id;
                    } else if (positions[id] < 0 && -positions[id] < Math.abs(closest)) {
                        closest = positions[id];
                        closestID = id;
                    }
                }

                // Activate the good link
                let link = anchors[closestID];

                link.addClass('is-active');
                activeLink = link;
                activeLinkID = closestID;
                activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                // Add hash to URL
                history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
            }
        };

        /**
         * Get anchors
         *
         * stored as [ id => element ]
         */
        let getAnchors = () => {
            el.find($(settings.linksContainer)).find('a').each(function () {
                let ID = $(this).attr('href').replace('#', '');

                anchors[ID] = $(this);
            });
        };

        /**
         * Get next link
         *
         * @param ID
         * @returns {boolean|string|*}
         */
        let getNextLink = (ID) => {
            let IDs = Object.keys(anchors),
                i   = IDs.indexOf(ID),
                l   = 0;

            for (let j in anchors) {
                if (i + 1 === l) return {id: j, link: anchors[j]};
                l++;
            }

            return false;
        };

        /**
         * Get prev link
         *
         * @param ID
         * @returns {boolean|string|*}
         */
        let getPrevLink = (ID) => {
            let IDs = Object.keys(anchors),
                i   = IDs.indexOf(ID) - 1,
                l   = 0;

            for (let j in anchors) {
                if (i === l) return {id: j, link: anchors[j]};
                l++;
            }

            return false;
        };

        /**
         * Get the actual scrolled item ID
         */
        let getActualScrolledID = () => {
            // Check if container has been scrolled past
            if (el.visibleInViewPort() && activeContent) {
                let scrollTop             = $(window).scrollTop(),
                    scrollPos             = $(document).scrollTop(),
                    activeContentPosition = activeContent.offset().top - 140,
                    activeContentHeight   = activeContent.outerHeight();

                if (scrollTop > lastScrollTop) {
                    // Downscroll
                    if (scrollPos >= (activeContentPosition + activeContentHeight)) {
                        // If active element has been passed
                        let nextLink = getNextLink(activeLinkID);

                        if (nextLink !== false) {
                            activeLink.removeClass('is-active');

                            activeLink = nextLink.link;
                            activeLinkID = nextLink.id;
                            activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                            activeLink.addClass('is-active');

                            // Add hash to URL
                            history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                        }
                    }
                } else {
                    // Upscroll
                    if (scrollPos <= activeContentPosition) {
                        // If active element has been passed
                        let prevLink = getPrevLink(activeLinkID);

                        if (prevLink !== false) {
                            activeLink.removeClass('is-active');

                            activeLink = prevLink.link;
                            activeLinkID = prevLink.id;
                            activeContent = el.find($(settings.contentContainer)).find(`[id="${activeLinkID}"]`);

                            activeLink.addClass('is-active');

                            // Add hash to URL
                            history.pushState ? history.pushState(null, null, `#${activeLinkID}`) : location.hash = `#${activeLinkID}`;
                        }
                    }

                }

                lastScrollTop = scrollTop;
            }
        };

        init();
    };
})(jQuery);