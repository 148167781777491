import "./components/sticky";
// import "./components/slick.min";
import "./components/anchor-block";
import "./components/flash-message";
import "./components/tab-change";
import "./components/owl-scrollbar";
import "./functions/file_upload";
import MaterialInput from "./components/MaterialInput";


(
	function ($) {
		/**
		 * Detect if the device is a touch device (tablet, mobile, ...)
		 *
		 * @returns {boolean}
		 */
		function isTouchDevice() {
			return "ontouchstart" in document.documentElement;
		}

		// Autosize textarea
		function autosize(el) {
			setTimeout(function () {
				$(el).css("height", "auto");
				$(el).css("height", parseInt(el.scrollHeight) + "px");
			}, 0);
		}

		// Have admin bar
		let haveAdminBar = () => {
			return $("#wpadminbar").length > 0;
		};

		// Is IE 11 browser ?
		function isIE11() {
			return !!navigator.userAgent.match(/Trident.*rv\:11\./);
		}

		// Create a div where needed
		function createDiv(className, append) {
			var output = '<div class="' + className + '"></div>';
			$(output).appendTo(append);
		}

		// Wrap block with a tag
		function wrap_block(el) {
			var element = $("." + el);
			if (element.length > 0) {
				element.each(function () {
					$(this).wrap($(this).find("a").first().clone().html(""));
				});
			} else {
				element.wrap(element.find("a").first().clone().html(""));
			}
		}

		// Have admin bar ?
		let haveAdminbar = () => {
			return $("body.admin-bar").length > 0;
		};

		// Adminbar height
		let adminbarHeight = () => {
			return $("#wpadminbar").height();
		};

		/**
		 * Back to top
		 *
		 * @param duration of the animation
		 */
		let backToTop = function (duration = 700) {
			$("html,body").animate(
				{
					scrollTop: 0
				},
				duration
			);
		};

		// Get window width
		let getWindowWidth = () => {
			return $(window).width();
		};

		// Get Scroll top
		let getScrollTop = () => {
			return $(window).scrollTop();
		};

		$.fn.classList = function () {
			return this[0].className.split(/\s+/);
		};

		let init = () => {
			// Init function
			/**
			 * Parallax scroll
			 */
			$(function () {
				/**
				 * Parallax on scroll
				 *
				 * @param intensity less is more intense, negative numbers : scroll to top, positive numbers : scroll to bottom
				 * @param direction vertical or horizontal
				 */
				$.fn.parallaxScroll = function (intensity, direction = "vertical") {
					let $el = $(this);

					$(window).on("load scroll", function () {
						window.requestAnimationFrame(function () {
							let scrollTop = $(window).scrollTop(),
								pos = scrollTop / intensity + "px";

							$el.each(function () {
								if (direction === "vertical") {
									$(this).css("transform", "translateY(" + pos + ")");
								} else if (direction === "horizontal") {
									$(this).css("transform", "translateX(" + pos + ")");
								}
							});
						});
					});
				};

				$(".image-text > div:nth-child(3) .image-text__parallax").parallaxScroll(
					5,
					"horizontal"
				);
				$(".blog-page__parallax").parallaxScroll(5, "horizontal");
				$(".single-page__parallax").parallaxScroll(5, "horizontal");
				$(
					".image-text--reverse > div:nth-child(3) .image-text__parallax"
				).parallaxScroll(-5, "horizontal");
				$(".home__posts-text__parallax").parallaxScroll(10, "horizontal");
				$(".home__prestations__content__background").parallaxScroll(
					-5,
					"horizontal"
				);
			});

			// Home posts slider
			let $homePostsSlider = $(".home__posts__slider");

			if ($homePostsSlider.length > 0) {
				$homePostsSlider.owlCarousel({
					dots: false,
					loop: false,
					margin: 35,
					responsive: {
						0: {
							items: 3,
							margin: 0
						},
						768: {
							items: 2
						},
						1248: {
							items: 3
						},
						1500: {
							margin: 115
						}
					}
				});
			}
		};

		/**
		 * Smooth scrolling to page anchor on click
		 */
		$(function () {
			$("a[href*='#']:not([href='#'])").click(function (e) {
				e.preventDefault();
				let target = this.hash;

				if (
					location.hostname === this.hostname &&
					this.pathname.replace(/^\//, "") ===
					location.pathname.replace(/^\//, "")
				) {
					var headerHeight =
						parseFloat(
							document.documentElement.style.getPropertyValue("--headerHeight")
						) ?? 0;
					var anchor = $(this.hash);
					anchor = anchor.length
						? anchor
						: $("[name=" + this.hash.slice(1) + "]");
					if (anchor.length) {
						$("html, body")
							.stop()
							.animate(
								{ scrollTop: anchor.offset().top - 50 - headerHeight },
								700,
								function () {
									if (history.pushState) {
										history.pushState(null, null, target);
									} else {
										location.hash = target;
									}
								}
							);
					}
				}
			});
		});

		$(document).ready(function () {
			MaterialInput.init();

			init();

			$(window).on("load resize", function () {
				var $headerResponsive = $(".header-responsive");
				var $headerDesktop = $(".header__wrapper");

				var $header = $headerResponsive.is(":visible")
					? $headerResponsive
					: $headerDesktop;

				document.documentElement.style.setProperty(
					"--headerHeight",
					$header[0].getBoundingClientRect().height + "px"
				);
			});

			$(".cookie-notice-close").on("click", function (e) {
				e.preventDefault();
			});

			// Auto resize textarea
			$("textarea.form-control").keydown(function (e) {
				let key = e.which || e.keyCode;
				if (key === 13 || key === 8) {
					autosize(this);
				}
			});
			
			
			
			$(".input-error").replaceWith(function(){
				return this.outerHTML.replace("<label", "<del").replace("</label", "</del")
			});
			
			$('.input-error').replaceWith(function() {
				return $('<div/>', {
					html: this.innerHTML
				});
			});
			
			$('.input-control input').on('focus', function() {
				if (!$(this).hasClass('input--focused')) {
				$(this).addClass('input--focused');    
				}
			}).on('blur', function() {
				if($(this).val() === '' && $(this).hasClass('input--focused')) {
				$(this).removeClass('input--focused')
				}  
			}) 
			
			$('.input-control textarea').on('focus', function() {
				if (!$(this).hasClass('input--focused')) {
				$(this).addClass('input--focused');    
				}
			}).on('blur', function() {
				if($(this).val() === '' && $(this).hasClass('input--focused')) {
				$(this).removeClass('input--focused')
				}  
			}) 

			/**
			 * Sticky Menu
			 */
			$(function () {
				let $header = $(".header"),
					$responsiveHeader = $(".header-responsive"),
					lastScrollTop = 0;

				$(window).on("load resize scroll", function () {
					if ($header.is(":visible") && $header.length > 0) {
						// Scroll comportment
						// $(window).on('scroll', function () {
						let currentPos = getScrollTop(),
							header_height = $header.height(),
							$tabChangeLinksStuck = $(".tab-change__links.is_stuck"),
							$anchorLinksStuck = $(".anchor-block__links.is_stuck");

						// If scrolled over header
						if (currentPos > header_height) {
							$header.removeClass("header--hidden");
							$header.addClass("header--fixed");

							if ($tabChangeLinksStuck.length > 0) {
								$tabChangeLinksStuck.css(
									"margin-top",
									header_height - parseInt($tabChangeLinksStuck.css("top")) + 40
								);
							}
							if ($anchorLinksStuck.length > 0) {
								$anchorLinksStuck.css(
									"margin-top",
									header_height - parseInt($anchorLinksStuck.css("top")) + 40
								);
							}
						} else if (currentPos === 0) {
							$header.removeClass("header--hidden header--fixed");
						}

						lastScrollTop = currentPos;
						// });
					} else if (
						$responsiveHeader.is(":visible") &&
						$responsiveHeader.length > 0
					) {
						// Scroll comportment
						// $(window).on('scroll', function () {
						let currentPos = getScrollTop(),
							header_height = $responsiveHeader.height(),
							topHeaderHeight = $(".top-header-responsive").height(),
							$responsiveMenu = $(".header-responsive__menu"),
							$responsiveMenuOverlay = $(".overlay--header-responsive"),
							$hamburger = $(".hamburger"),
							$tabChangeLinksStuck = $(".tab-change__links.is_stuck"),
							$anchorLinksStuck = $(".anchor-block__links.is_stuck");

						// If scrolled over header
						if (currentPos > topHeaderHeight + header_height) {
							$responsiveHeader.removeClass("header-responsive--hidden");
							$responsiveHeader.addClass("header-responsive--fixed");

							if ($tabChangeLinksStuck.length > 0) {
								$tabChangeLinksStuck.css(
									"margin-top",
									header_height - parseInt($tabChangeLinksStuck.css("top")) + 40
								);
							}
							if ($anchorLinksStuck.length > 0) {
								$anchorLinksStuck.css(
									"margin-top",
									header_height - parseInt($anchorLinksStuck.css("top")) + 40
								);
							}
						} else if (currentPos < lastScrollTop) {
							$responsiveHeader.removeClass(
								"header-responsive--hidden header-responsive--fixed"
							);
						}

						lastScrollTop = currentPos;
						// });
					}
				});
			});

			/**
			 * Home videos carousel
			 */
			$(function () {
				let $container = $(".home__videos .owl-carousel");

				if ($container.length > 0) {
					$container.owlCarousel({
						items: 2,
						margin: 25,
						loop: true,
						dots: false,
						nav: true,
						navText: [
							'<span class="arrow"><span class="arrow__head"></span></span>',
							'<span class="arrow"><span class="arrow__head"></span></span>'
						],
						responsive: {
							0: {
								items: 1,
								margin: 15,
								stagePadding: 15
							},
							900: {
								items: 2,
								margin: 25,
								stagePadding: 50
							},
							1248: {
								stagePadding: 0
							}
						}
					});
				}
			});

			/**
			 * Youtube video, on click on the button, launch the video
			 */
			$(function () {
				$(".embed-container__thumbnail__button").on("click touch", function () {
					let $parent_container = $(this).parent().parent(),
						$thumbnail_container = $parent_container.find(
							".embed-container__thumbnail"
						),
						$iframe = $parent_container.find("iframe");

					// Launch the video
					$iframe.attr("src", $iframe.attr("src") + "?autoplay=1");
					$parent_container.addClass("is-active");
					setTimeout(function () {
						$thumbnail_container.fadeOut();
					}, 500);
				});
			});

			/**
			 * Back to top
			 */
			$(function () {
				/* Display back to top after a certain scroll amount */

				let $backToTop = $(".back-to-top"),
					scroll_amount = 600;

				if ($backToTop.length > 0) {
					$(window).on("load scroll resize", function () {
						let right = $(".footer > .container").css("padding-right"),
							scrollTop = $(window).scrollTop();

						if (scrollTop > scroll_amount) {
							$backToTop.css("right", right);
						} else {
							$backToTop.removeAttr("style");
						}
					});
				}

				/* On click, back to top */

				$backToTop.on("click", function () {
					backToTop();
				});
			});

			/**
			 * Mega menu
			 */
			$(function () {
				$("li.mega-menu").hoverIntent({
					over: function () {
						$(this).addClass("mega-menu--is-active");
					},
					out: function () {
						$(this).removeClass("mega-menu--is-active");
					},
					timeout: 100
				});
			});

			/**
			 * Simple menu
			 */
			$(function () {
				$("li.simple-menu").hoverIntent({
					over: function () {
						$(this).addClass("simple-menu--is-active");
					},
					out: function () {
						$(this).removeClass("simple-menu--is-active");
					},
					timeout: 100
				});
			});

			/**
			 * Responsive header
			 *
			 * - Hamburger
			 * - Menu
			 */
			$(function () {
				/* Hamburger */
				$(".hamburger").on("click", function () {
					$(this).toggleClass("is-active");
					$(".header-responsive__menu").slideToggle();
					$(".overlay--header-responsive").fadeToggle();
				});

				/* Overlay */
				$(".overlay--header-responsive").on("click", function () {
					$(".hamburger").removeClass("is-active");
					$(".header-responsive__menu").slideUp();
					$(".overlay--header-responsive").fadeOut();
				});

				/* Prevent click on void + Sub menu */
				$(".header-responsive__menu li.menu-item-has-children > a").on(
					"click",
					function (e) {
						e.preventDefault();

						$(this)
							.parent()
							.toggleClass("is-active")
							.find(".sub-menu")
							.first()
							.slideToggle();
					}
				);

				/* Move to the page clicked on */
				$(".header-responsive__menu li.menu-item-has-children > a > span").on(
					"click",
					function (e) {
						e.stopPropagation();
					}
				);
			});

			/**
			 * Home Prestations
			 *
			 * - Add a tag <a /> to all prestations
			 */
			$(function () {
				let $prestation = $(".home__prestation");

				if ($prestation.length > 0) {
					$prestation.each(function () {
						let $prestationLink = $(this).find("a"),
							link = $prestationLink.attr("href"),
							classesUses = $(this).classList().join(" "),
							element = $("<a />").addClass(classesUses).attr("href", link);

						$prestationLink.contents().unwrap();
						$(this).contents().unwrap().wrap(element);
					});
				}
			});

			/**
			 * Change default settings of google reviews plugin
			 */
			$(function () {
				if ($(".google-reviews__carousel").length > 0) {
					var el = document.querySelector(".google-reviews__carousel");
					var swiperEl = el.querySelector(".rplgsw-container"),
						swiperData = {
							loop: true,
							autoplay: 1500,
							slidesPerView: 3,
							spaceBetween: 40,
							autoHeight: true,
							nextButton: el.querySelector(
								".google-reviews__carousel__arrows__right"
							),
							prevButton: el.querySelector(
								".google-reviews__carousel__arrows__left"
							),
							breakpoints: {
								767: {
									slidesPerView: 1,
									spaceBetween: 10
								},
								800: {
									slidesPerView: 3 > 1 ? 2 : 1,
									spaceBetween: 20
								}
							},
							onTransitionEnd: function (swiper) {
								window.rplg_blazy && window.rplg_blazy.revalidate();
							}
						};
					$(window).on("resize", function () {
						return new Rplgsw(swiperEl, swiperData);
					});
					return new Rplgsw(swiperEl, swiperData);
				}
			});

			/**
			 * Carousel full right
			 */
			$(function () {
				let container = $(".carousel-full-right");

				if (container.length > 0) {
					container.find(".owl-carousel").owlCarousel({
						items: 1,
						loop: false,
						dots: false,
						nav: false,
						margin: 15,
						stagePadding: 50,
						responsive: {
							0: {
								items: 1,
								stagePadding: 25
							},
							480: {
								items: 1,
								stagePadding: 50
							},
							768: {
								items: 2,
								stagePadding: 100
							}
						}
					});
				}
			});

			function toggleSearch() {
				if (!isIE11()) {
					$(".overlay__black--80, .search-container").fadeToggle();
					$(".search__input--container").css("left", 0);
					$(".search__btn").css("right", 0);

					if (!isTouchDevice()) {
						$(".search__input--container input").focus();
					}

					setTimeout(function () {
						$(".search__close").fadeToggle();
					}, 100);
				} else {
					$(".search-container, .overlay__black--80, .search__close").show();

					if (!isTouchDevice()) {
						$(".search__input--container input").focus();
					}
				}
			}

			$(".antipodes-header-main__search").click(function (e) {
				e.preventDefault();
				toggleSearch();
			});

			$(".header__responsive--extras").click(function (e) {
				e.preventDefault();
				toggleSearch();
			});


			$(".search__close").click(function () {
				if (!isIE11()) {
					$(".search__close").fadeToggle();
					setTimeout(function () {
						if (!isIE11()) {
							$(".search__input--container").css("left", "");
							$(".search__btn").css("right", "");
						}
						$(".search-container").fadeToggle();
						$(".overlay__black--80").fadeToggle();
					}, 100);
				} else {
					$(".search-container").hide();
					$(".overlay__black--80").hide();
					$('.search__close').hide();
				}
			});
		});

		/**
		 * Anchor block
		 */
		$(function () {
			$(".anchor-block").anchorBlock();
		});

		/**
		 * Remove accordion animation, jumping issues
		 */
		$(function () {
			$(document).on(
				"click",
				"li.vc_tta-tab a,li.vc_tta-tab,.vc_tta-panel-title",
				function () {
					$("html, body").stop();
				}
			);
		});
		$(document).ready(function () {
			// Gestion pour le tableau Femme
			$('.tabs-femme').each(function () {
			  var $tabsContainer = $(this);
		  
			  $tabsContainer.find('.tabs__header .tab').on('click', function () {
				var $this = $(this);
				var tabId = $this.data('tab');
		  
				// Gérer la classe active pour les onglets Femme
				$tabsContainer.find('.tab').removeClass('active');
				$this.addClass('active');
		  
				// Gérer la classe active pour le contenu Femme
				$tabsContainer.find('.tab-content').removeClass('active');
				$tabsContainer.find(`.tab-content[data-tab="${tabId}"]`).addClass('active');
			  });
			});
		  
			// Gestion pour le tableau Homme
			$('.tabs-homme').each(function () {
			  var $tabsContainer = $(this);
		  
			  $tabsContainer.find('.tabs__header .tab').on('click', function () {
				var $this = $(this);
				var tabId = $this.data('tab');
		  
				// Gérer la classe active pour les onglets Homme
				$tabsContainer.find('.tab').removeClass('active');
				$this.addClass('active');
		  
				// Gérer la classe active pour le contenu Homme
				$tabsContainer.find('.tab-content').removeClass('active');
				$tabsContainer.find(`.tab-content[data-tab="${tabId}"]`).addClass('active');
			  });
			});
		  });
		  
		  
		  
		  
		  
		  
		  
		/**
		 * 404 page
		 */
		$(function () {
			let $container = $("body.error404");

			if ($container.length > 0) {
				$(window).on("load resize", function () {
					let $header = $container.find(".header"),
						$breadcrumb = $container.find(".breadcrumb"),
						isResponsiveHeader = false,
						$page_wrapper = $container.find(".page-wrapper"),
						$footer = $container.find(".footer"),
						$copyright = $container.find(".copyright"),
						window_height = $(window).height(),
						header_height = 0,
						breadcrumb_height = $breadcrumb.outerHeight(),
						footer_height = $footer.outerHeight() + $copyright.outerHeight();

					isResponsiveHeader = !!$header.is(":hidden");
					if (!isResponsiveHeader) {
						header_height = $header.outerHeight();
					} else {
						header_height =
							$container.find(".top-header-responsive").outerHeight() +
							$container.find(".header-responsive").outerHeight();
					}

					let page_wrapper_height =
						window_height - (
							header_height + footer_height
						);

					if (haveAdminBar()) {
						page_wrapper_height -= $("#wpadminbar").outerHeight();
					}

					$page_wrapper.css({
						height: page_wrapper_height,
						"padding-bottom": breadcrumb_height
					});
				});
			}
		});

		/**
		 * Tab change plugin
		 */
		$(function () {
			$(".tab-change").tabChange();
		});

		/**
		 * flash-message
		 */
		$(function () {
			$(".flash-message").tabChange();
		});

		/**
		 * validate
		 */
		$(function () {
			$(".validate").tabChange();
		});

		/**
		 * Home posts scrollbar adjustment
		 */
		$(function () {
			let $homePosts = $(".home__posts__content");

			if ($homePosts.length > 0) {
				$(window).on("load resize", function () {
					let marginRight = parseInt($homePosts.css("margin-right")) * -1;

					$homePosts
						.find(".owl-scrollbar")
						.css("padding-right", marginRight - 15);
				});
			}
		});

		/**
		 * Same width & height buttons
		 */
		$(function () {
			let $container = $(".more-informations");

			if ($container.length > 0) {
				$container.each(function () {
					let _this = $(this);
					$(window).on("load resize", function () {
						let maxWidth, maxHeight;

						// Get max width & max height of all buttons
						maxWidth = Math.max.apply(
							null,
							_this
								.find("a")
								.map(function () {
									return $(this).removeAttr("style").outerWidth(true);
								})
								.get()
						);

						maxHeight = Math.max.apply(
							null,
							_this
								.find("a")
								.map(function () {
									return $(this).removeAttr("style").outerHeight(true);
								})
								.get()
						);

						_this.find("a").each(function () {
							$(this).css({
								width: maxWidth,
								height: maxHeight
							});
						});
					});
				});
			}
		});

		/**
		 * Carousel add grab
		 */
		$(function () {
			let $carousel = $(".owl-carousel");

			if ($carousel.length > 0) {
				$carousel.each(function () {
					$carousel.on("initialized.owl.carousel", function (e) {
						if (e.item.count > 1) {
							$(this).css("cursor", "grab");
						}
					});
				});
			}
		});

		$(function () {
			$('.photoCaroussel .vc_col-sm-12 > div > div').addClass('slick-carousel');
			$('.photoCaroussel .slick-carousel').slick({
				infinite: false,
				slidesToShow: 4.5,
				slidesToScroll: 1,
				dots: true,
				arrows: true,
				responsive: [
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 3.5,
						}
					},
					{
						breakpoint: 900,
						settings: {
							slidesToShow: 2.5,
						}
					},
					{
						breakpoint: 700,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 550,
						settings: {
							slidesToShow: 1.5,
						}
					},
				]
			});
		});

		$(function () {
			const $headerMainLogo = $(".header__main__logo");
			$(window).on("load resize", function () {
				if ($headerMainLogo) {
					document.documentElement.style.setProperty(
						"--headerMainLogoWidth",
						`${$headerMainLogo[0].getBoundingClientRect().width}px`
					);
				}
			});
		});

		$(function () {
			var $videoPlayers = $("[data-is-plyr]");
			$videoPlayers.each(function () {
				new Plyr($(this)[0]);
			});
		});
		// $(document).ready(function() {
		// 	// Sélectionner toutes les vidéos sur la page
		// 	$('video').each(function() {
		// 		var video = this;
		
		// 		$(video).on('loadeddata', function() {
		// 			video.currentTime = 5;
		// 		});
		
		// 		// Lors du clic sur la vidéo, remettre la vidéo à 0 seconde et démarrer la lecture
		// 		$(video).on('click', function() {
		// 			video.currentTime = 0;  // Remet à 0 seconde
		// 			video.play();  // Démarre la lecture
		// 		});
		// 	});
		// });
		
		
		

		

	}
)(jQuery);