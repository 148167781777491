(function ($) {

    /**
     * File upload style + script to add image
     */
    $(function () {
        let file_upload_container     = $('.file-upload'),
            file_upload_text          = $('.file-upload__text'),
            file_upload_input         = $('.file-upload__input'),
            file_upload_default_input = file_upload_container.find('input[type="file"]');

        // On click on the fake input, click on the file input
        file_upload_input.on('click', function () {
            file_upload_default_input.click();
        });

        // Text of the input
        file_upload_default_input.on('change', function () {
            let text = this.files[0].name; // Default text

            if (this.files.length > 1) {
                text = `${this.files.length} fichiers`;
            }

            file_upload_text.html(text);
        });

        // Reset file upload on mail sent
        $('.wpcf7').each(function () {
            $(this).on('wpcf7mailsent', function (event) {
                $(this).find(file_upload_input).val('');
                $(this).find(file_upload_text).html('Aucun fichier choisi');
            });
        });
    });

})(jQuery);