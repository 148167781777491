(function ($) {

    $(function () {
        let $owlScrollBar = $('.owl-scrollbar');

        if ($owlScrollBar.length > 0) {
            $owlScrollBar.each(function () {
                let $scrollBar      = $(this),
                    $barContainer   = $scrollBar.find('.bar-container'),
                    $bar            = $scrollBar.find('.bar'),
                    $carousel       = $scrollBar.prev(),
                    perPage, itemCount;

                // Initialize the bar width depending on the number of elements in the carousel
                $carousel.on('initialized.owl.carousel', function (e) {
                   perPage = e.page.size;
                   itemCount = $carousel.find('.owl-item:not(.cloned)').length - (perPage - 1);

                   let barWidth = 100 / itemCount;

                   $bar.css('width', `${barWidth}%`)
                });

                // Move the scrollbar when the owl carousel is dragged
                $carousel.on('dragged.owl.carousel', function (e) {
                    let barPosition = $bar.width() * e.item.index;
                    $bar.animate({
                        left: barPosition
                    }, 200);
                });

                // Scrollbar draggable comportment
                $bar.draggable({
                   axis: 'x',
                   containment: 'parent',
                   drag: function (e, ui) {
                       // Calcul the item to display depending on the bar position
                       let scrollPos = Math.round(ui.position.left / $bar.width());
                       $carousel.trigger('to.owl.carousel', scrollPos);
                   }
                });
            });
        }
    });

})(jQuery);